.select-block {
  width: 250px;
}

.react-select-container .react-select__control {
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.react-select-container .react-select__single-value {
  color: white;
  font-size: 14px;
}

.react-select-container .react-select__value-container .react-select__menu {
  color: white;
}

.react-select-container .react-select__menu-list {
  color: white;
}

.react-select-container .react-select__menu-list .react-select__option {
  color: white;
}

.react-select__menu {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.react-select__menu-list .react-select__option {
  margin-top: 2px;
  border-radius: 5px;
  color: white;
}

.react-select__option:hover,
.react-select__option:focus {
  color: #ff4700;
}

.react-select-container
  .react-select__control
  .react-select__value-container
  .react-select__indicators
  .react-select__value-container
  .react-select__menu
  .react-select__menu-list
  .react-select__option {
  background-color: red;
}
